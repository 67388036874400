import firebase from '../configs/firebase'
import userApi from '../api/user'
import store from '../store'
export default new class {
  constructor () {
    this._applicationServerPublicKey = 'BESd3TirU3vG2tskvmMxNi8Q94G-HwRYa8CJQX49ffEcZVcFFywJlHICAgIAV-Zi5HR92UWkrmWvbFyHkROHLqU'
    this._token = null

    /* eslint-disable */
    try {
      if (window.cordova) {
        
        document.addEventListener('deviceready', () => {
          console.log('PLUGINS', window.cordova.plugins)
          this._messaging = cordova.plugins.firebase.messaging
        }, false)
        
      } else {
        this._messaging = firebase.messaging()
      }    
    } catch (err) {
      console.error('FIREBASE SDK', err)
    }
    /* eslint-enable */

    // this._messaging = firebase.messaging()
  }

  async removeToken () {
    const messaging = this._messaging

    try {
      const oldToken = await messaging.getToken()
      await messaging.deleteToken()
      await userApi.removeDevice(oldToken)
      console.log('Removed Token:', oldToken)
    } catch (err) {
      console.error(`Error at remove token:`, err)
    }
  }

  getToken () {
    return this._token
  }

  setFCM () {
    if (process.env.VUE_APP_CORDOVA) {
      // this.setCordovaFCM()
    } else {
      this.setWebFCM()
    }
  }

  /**
   * @description Set a firebase cloud messaging service worker to handle notifications
   * @param {String} institution Current institution
   */
  setWebFCM () {
    const messaging = this._messaging

    if ('serviceWorker' in navigator && 'PushManager' in window) {
      console.log('Use valid api key')
      messaging.usePublicVapidKey(this._applicationServerPublicKey)

      // Get token
      messaging.getToken().then((currentToken) => {
        if (currentToken) {
          // Save user device
          this._token = currentToken

          console.log('notifications', 'setWebFCM', 'token got', this._token)

          userApi.saveDevice({
            push_token: currentToken,
            platform: 'web'
          })
        } else {
          throw new Error('Invalid Token Received')
        }
      }).catch((err) => {
        console.error(err)
      })

      messaging.onTokenRefresh(async () => {
        await this.removeToken()

        messaging.getToken().then((refreshedToken) => {
          this._token = refreshedToken
          console.log('notifications', 'setWebFCM', 'token refreshed', this._token)

          if (refreshedToken) {
            // Save user device
            userApi.saveDevice({
              push_token: refreshedToken,
              platform: 'web'
            })
          } else {
            throw new Error('Invalid Token Received')
          }
        }).catch((err) => {
          console.error(err)
        })
      })

      // Allow notifications
      Notification.requestPermission()
    }
  }

  setCordovaFCM () {
    const messaging = this._messaging
    messaging.requestPermission().then(function () {
      console.log('Push messaging is allowed')
    })

    // platformSlug should receive android | ios
    const platformSlug = window.cordova ? window.cordova.platformId : 'web'
    console.log('MESSAGING', messaging)

    console.log('ON CORDOVA ENVIROMENT')
    try {
      document.addEventListener('deviceready', () => {
        console.log('ON CORDOVA ENVIRMENT -- DEVICE READY')
        // triggado quando o App tá aberto
        messaging.onMessage(function (payload) {
          const data = payload.gcm
          console.log('Cordova Notification Received on Foreground', payload)
          store.dispatch('app/showSnackbar', data.title)
        })

        // triggado quando o App tá fechado
        messaging.onBackgroundMessage(function (payload) {
          console.log('Cordova Notification Received on Background', payload)
          // const data = payload.gcm
          if (payload.tap) {
            // Clicou na notificação and now?
            // payload.type == 'post' ou payload.type == 'message' can be useful
            console.log('CLICKED ON NOTIFICATION')
          } else {
            console.log('NOT CLICKED ON NOTIFICATION?')
            // const notificationTitle = payload.title
            // const notificationOptions = {
            //   media: 'https://escolaconecta.com/wp-content/uploads/2019/06/Weblogo-Conecta.png',
            //   title: 'TITULO DA NOTI',
            //   body: payload.body
            // }
          }
        })
      }, false)
    } catch (err) {
      console.error('FIREBASE SDK', err)
    }

    const saveToken = (token) => {
      if (token) {
        this._token = token

        userApi.saveDevice({
          push_token: token,
          platform: platformSlug
        })
      } else {
        throw new Error('Invalid Token Received')
      }
    }

    messaging.getToken().then(saveToken)

    messaging.onTokenRefresh(async () => {
      console.log('Refreshing token on web')

      await this.removeToken()

      messaging.getToken().then(saveToken)
    })
  }
}()
