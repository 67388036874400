import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueLoaders from 'vue-loaders'
import './configs/firebase'
import './plugins'

Vue.config.productionTip = false
Vue.use(VueLoaders)

// const PromiseCordova = new class {
//   constructor () {
//     this._Promise = new Promise((this.resolve))
//   }
// }()

new Vue({
  fiery: true,
  router,
  store,
  render: h => h(App),
  vuetify,
  created () {
  }
}).$mount('#app')
