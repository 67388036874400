<template lang="pug">
  div(:style="scrollStyle")
    v-container(v-if="step === 0")
      v-row(v-if="flags.loadingChildren" justify='center')
        v-col(cols='auto')
          v-progress-circular(size="70" indeterminate color="primary")
      div(v-else)
        v-row(justify-center v-if="!childrenList || (childrenList && childrenList.length == 0)")
          v-col(cols='12' )
            span.title.grey--text Nenhum filho cadastrado
          v-col(cols='12')
            span.caption.grey--text Isto pode acontecer devido ao período de matrículas ou algum processo de manutenção rotinera da instituição de Ensino.
          v-col(cols='12')
            span.caption.grey--text Caso suspeito que isto seja um erro entre em contato com a Instituição de Ensino sobre o assunto.
        //- v-row(justify-center v-if="childrenList && childrenList.length == 1")
        v-row(justify-center v-if="childrenList && childrenList.length")
          v-col(cols='12')
            span.caption.grey--text Selecione um aluno para continuar
          v-col(cols='12')
            v-list
              div(v-for="(student,index) in childrenList" :key="student.id")
                v-divider(v-if='index > 0')
                v-list-item(@click="selectStudent(student)" large)
                  v-list-item-avatar(v-if="student.profile_image_url && student.profile_image_url.length > 0")
                    v-img(:src="student.profile_image_url" width="35px")
                  v-list-item-avatar(v-else)
                    v-icon(size=50) account_circle
                  v-list-item-content
                    v-list-item-title {{student.name}}
                    v-list-item-subtitle {{student.groups_name}}
    v-container(v-else-if="step === 1")
      div(v-if="!postCategories")
        v-row.justify-center
          v-progress-circular(size="70" indeterminate color="primary")
      div(v-else)
        v-btn(text outiline @click="backToStudent" class="ml-0 mr-0 pl-0 pr-0" v-if="childrenList && childrenList.length > 1")
          v-icon mdi-keyboard-backspace
          span Voltar
        v-row.justify-center( class="ml-0 mr-0 pl-0 pr-0")
          v-list-item-avatar(v-if="selectedStudent.profile_image_url && selectedStudent.profile_image_url.length > 0" size=150)
            v-img(:src="selectedStudent.profile_image_url" width="35px")
          v-list-item-avatar(v-else size=150)
            v-icon(size=150) account_circle
        v-row.justify-center
          span.title {{selectedStudent.name}}
        v-row.justify-center
          span.caption {{selectedStudent.groups_name}}
        v-row(justify-center)
          //-v-col(cols='12' class="ml-0 mr-0 pl-0 pr-0")
            span.caption.grey--text Selecione uma categoria para continuar
          v-col(cols='12' class="ml-0 mr-0 pl-0 pr-0" v-if="postCategories && postCategories.length")
            v-list()
              div(v-for="(category,index) in postCategories" :key="category.id")
                //- v-divider(v-if='index > 0')
                v-list-item(@click="selectCategory(category)" class="ml-0 mr-0 pl-0 pr-0")
                  v-alert.pa-0.ma-0.mb-2( border="left" colored-border :color="category.color" elevation="0" width="100%" height="50")
                    v-row.ml-5
                      v-list-item-avatar
                        v-icon(:color="category.color") {{category.icon}}
                      v-list-item-content {{category.name}}
          v-col(cols='12' class="ml-0 mr-0 pl-0 pr-0" v-else)
            span.caption Nenhuma serviço encontrado para este aluno, caso isso pareça um erro entre em contato com a Instituição de Ensino.
    v-container(v-else-if="step === 2" fluid)
      v-container(v-if="showingPosts && !isDynamicComponent")
        div(v-if="!categoryPosts")
          v-row.justify-center
            v-progress-circular(size="70" indeterminate color="primary")
        div#post-list(fluid v-else)
          v-btn(text outiline @click="backToCategories")
            v-icon mdi-keyboard-backspace
            span Voltar
          v-row.justify-center()
            v-col(cols="auto")
              v-row.justify-center
                span.subtitle-1.font-weight-medium() {{selectedCategory.name}}
              v-row.justify-center
                span.subtitle-1.font-weight-medium() {{selectedCategory.description}}
          v-row
            v-col(xs='12' sm='8' lg='6' offset-sm='2' offset-lg='3')
              Post(v-for="(item, index) in categoryPosts" :key="item.id" :post='item' :intersectionObserver="intersectionObserver")
            v-col.text-center( cols='12' xs='12' sm='8' lg='6' offset-sm='2' offset-lg='3' v-show="loading" size=100 key='flex')
              v-progress-circular.mt-5(:size="50" color="primary" indeterminate)
      div(v-else style="overflow: hidden;")
        v-btn(text outiline @click="backToCategories")
            v-icon mdi-keyboard-backspace
            span Voltar
        template(v-if="externalReport")
          component( :is="externalReport" :matricula="selectedStudent.matricula" style="overflow: hidden;")
        template(v-else)
          v-row.justify-center
            v-progress-circular(size="70" indeterminate color="primary")

</template>
<script>
import PostApi from '../api/posts.js'
import Post from '../components/feed/PostViewer'
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import studentApi from '../api/students'
import menu from '@/api/menu'
import importExternalComponent from '../utils/importExternalComponent'
import IntersectionObserver from 'intersection-observer-polyfill'
// import User from '../api/user'
export default {
  components: {
    Post
  },
  data () {
    return {
      intersectionObserver: null,
      step: 0,
      flags: {
        loadingChildren: true
      },
      postCategories: null,
      showingPosts: false,
      categoryPosts: null,
      selectedCategory: null,
      loading: false,
      selectedStudent: null,
      externalReport: null,
      isDynamicComponent: false
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapFields('responsible', ['childrenList']),
    scrollStyle () {
      const style = `height: calc(100vh - ${this.$vuetify.application.top + this.$vuetify.application.bottom}px); overflow: auto;`
      console.log('Setting style', style)
      return style
    }
  },
  async created () {
    // var userStudents = []
    // this.studentList = await User.getUserType(['aluno'])

    await this.$store.dispatch('user/getMe')
    await this.$store.dispatch('responsible/getChildren', this.user)

    this.flags.loadingChildren = false
    // var resCategories = await PostApi.getCategories(this.$route.params.institution)
    // this.postCategories = resCategories.data
  },
  watch: {
    childrenList (data) {
      if (data.length === 1) {
        console.log('Apenas um filho')
        this.selectStudent(data[0])
      }
      console.log('Watched for changes')
    }
  },
  methods: {
    async onIntersect (entries, observer) {
      if (entries && entries.length > 0 && entries[0].target && entries[0].target.attributes && entries[0].target.attributes['post-id']) {
        const postIdAttribute = entries[0].target.attributes['post-id']
        const postId = postIdAttribute.value
        this.$store.dispatch('posts/read', {
          id: postId,
          observer: this.intersectionObserver,
          target: entries[0].target
        })
      }
    },
    async selectStudent (student) {
      this.step = 1
      this.selectedStudent = student
      this.postCategories = await studentApi.getCategories(student.id)
      console.log('CATEGORIES', this.postCategories)
      var menuItems = await menu.get()
      for (let item of menuItems) {
        if (item.section === 'Categories') {
          item.name = item.title
          this.postCategories.push(item)
        }
      }
      this.postCategories = this.postCategories.sort(function (a, b) {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        }
        if (b.name.toLowerCase() > a.name.toLowerCase()) {
          return -1
        }
        return 0
      })
    },
    backToStudent () {
      this.step = 0
      this.postCategories = null
      this.selectedStudent = null
    },
    backToCategories () {
      this.step = 1
      this.showingPosts = false
      this.isDynamicComponent = false
      this.categoryPosts = null
      this.loading = false
      this.selectedCategory = null
    },
    async selectCategory (category) {
      this.step = 2
      this.selectedCategory = category
      this.showingPosts = true
      if (category.dynamic_component_path) {
        this.isDynamicComponent = true
        this.externalReport = await importExternalComponent(category.dynamic_component_path)
      } else {
        this.categoryPosts = []
        this.loadMore()
      }
    },
    async handleScroll (e) {
      const listElm = window
      if (listElm.scrollY + (4 * listElm.innerHeight) >= document.body.scrollHeight && this.loading === false && this.showingPosts && this.categoryPosts) {
        this.loadMore()
      }
    },
    async loadMore () {
      this.loading = true
      if (this.categoryPosts) {
        var lastPost = this.categoryPosts[this.categoryPosts.length - 1]
        let lastItem = lastPost ? lastPost.created_time : ''
        const queryParams = {
          last: lastItem,
          categoryId: this.selectedCategory.id,
          selectedGroups: [this.selectedStudent.groups_cod.split(',')],
          selectedAssocUsers: [this.selectedStudent.id]
          // recipientId: this.selectedStudent.id, o pai já é recipient.. isso fará com que uma row sql seja obrigada a ter dois recipients que é impossível.
        }
        console.log('QUERY', queryParams)
        const list = await PostApi.list(queryParams)
        this.categoryPosts = this.categoryPosts.concat(list)
      }
      this.loading = false
    }
  },
  destroyed () {
    // Detect when scrolled to bottom.
    const listElm = window
    listElm.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    // Detect when scrolled to bottom.
    const listElm = window
    listElm.addEventListener('scroll', this.handleScroll)

    this.intersectionObserver = new IntersectionObserver(this.onIntersect, {
      root: document.querySelector('#post-list'),
      rootMargin: '0px',
      threshold: 0.3
    })
  }
}
</script>
<style scoped>

</style>
