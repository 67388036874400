<template>
  <v-container fluid>
    <v-timeline dense clipped v-if="this.catracaItems">
      <v-timeline-item v-for="item in catracaToShow" :key="item.horario" :color="colorByEvent(item.evento)" :small="isSmall(item.evento)">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> {{item.local}} </v-list-item-title>
            <v-list-item-subtitle> {{item.horario}} </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-timeline-item>
    </v-timeline>
    <div v-else>
      <v-row class="justify-center">
        <v-progress-circular size="70" indeterminate color="primary"> </v-progress-circular>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import catracaApi from '../api/catraca'
export default {
  props: {
    matricula: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      catracaItems: [],
      catracaToShow: []
    }
  },
  async created () {
    this.catracaItems = await catracaApi.list(this.matricula)
    this.processCatraca()
  },
  methods: {
    colorByEvent (event) {
      if (event === 'data') return 'grey'
      else if (event === 'entrada') return 'green'
      else {
        return 'red'
      }
    },
    isSmall (event) {
      if (event === 'data') return false
      else {
        return true
      }
    },
    processCatraca () {
      var lastDate = null
      for (let item of this.catracaItems) {
        var date = this.$moment(item.horario).format('YYYYMMDD')
        if (lastDate === null) {
          lastDate = date
          this.catracaToShow.push({
            'evento': 'data',
            'horario': this.$moment(lastDate).format('Do MMMM YYYY')
          })
        } else if (date < lastDate) {
          lastDate = date
          this.catracaToShow.push({
            'evento': 'data',
            'horario': this.$moment(lastDate).format('Do MMMM YYYY')
          })
        }
        this.catracaToShow.push({
          'local': item.local,
          'evento': item.evento,
          'horario': this.$moment(item.horario).format('hh:mm:ss')
        })
      }
    }
  }
}
</script>
