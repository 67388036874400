<template lang="pug">
  v-container
    v-row(justify='center' align='center')
      v-col
        v-select(v-model='chatConfig.from_group' placeholder="Grupo pertencente"  :items='groups' item-text='cod' item-value='cod')
      v-col
        v-select(v-model='chatConfig.from_type' placeholder="Tipo pertencente"  :items='types' item-text='cod' item-value='cod')
      v-col
        v-select(v-model='chatConfig.to_group' placeholder="Grupo destinatário"  :items='groups' item-text='cod' item-value='cod')
      v-col
        v-select(v-model='chatConfig.to_type' placeholder="Tipo destinatário"  :items='types' item-text='cod' item-value='cod')
      v-col(cols='auto')
        v-btn(@click="addPerm" color='primary' :loading="flags.addingConfig")
          v-icon() mdi-plus
    v-row
      v-col
        v-data-table(:headers='headers' :items='perms')
          template(v-slot:item.delete="{ item }")
            v-btn(icon @click="deleteChatConfig(item)" :loading="item.deleting")
              v-icon delete
</template>

<script>
import ChatApi from '../api/chat'
export default {
  data () {
    return {
      perms: [],
      chatConfig: {
        from_group: '',
        from_type: '',
        to_group: '',
        to_type: ''
      },
      groups: [],
      types: [
        {
          text: 'Qualquer um',
          cod: 'ANY'
        },
        {
          text: 'professor',
          cod: 'professor'
        },
        {
          text: 'aluno',
          cod: 'aluno'
        },
        {
          text: 'parent',
          cod: 'parent'
        },
        {
          text: 'admin',
          cod: 'admin'
        },
        {
          text: 'meca',
          cod: 'meca'
        }
      ],
      flags: {
        addingConfig: false
      },
      headers: [
        {
          text: 'Grupo pertencente',
          align: 'center',
          sortable: false,
          value: 'from_group'
        },
        {
          text: 'Tipo pertencente',
          align: 'center',
          sortable: false,
          value: 'from_type'
        },
        {
          text: 'Grupo destinatário',
          align: 'center',
          sortable: false,
          value: 'to_group'
        },
        {
          text: 'Tipo destinatário',
          align: 'center',
          sortable: false,
          value: 'to_type'
        },
        {
          text: 'Remover item',
          align: 'center',
          value: 'delete',
          sortable: false
        }
      ]
    }
  },
  async created () {
    this.perms = await ChatApi.listConfig()
    this.groups = await this.$store.dispatch('groups/list')
    this.groups.unshift({
      text: 'Qualquer um',
      cod: 'ANY'
    })
  },
  computed: {
    canAddToPerms () {
      return this.chatConfig.from_group !== '' && this.chatConfig.from_type !== '' && this.chatConfig.to_group !== '' && this.chatConfig.to_type !== ''
    }
  },
  methods: {
    async addPerm () {
      try {
        this.flags.addingConfig = true
        const chatConfig = await ChatApi.addChatConfig(this.chatConfig)
        this.perms.push(chatConfig)
        this.chatConfig.from_group = this.chatConfig.from_type = this.chatConfig.to_group = this.chatConfig.to_type = ''
      } catch (Err) {
        console.error(Err)
      }
      this.flags.addingConfig = false
    },
    async deleteChatConfig (chatConfig) {
      try {
        console.log('DELETE', chatConfig)
        chatConfig.deleting = true
        await ChatApi.deleteChatConfig(chatConfig)
        this.perms = this.perms.filter(p => p.id !== chatConfig.id)
        chatConfig.deleting = false
        // this.perms = await ChatApi.listConfig()
      } catch (Err) {
        console.error(Err)
      }
    }
  }
}
</script>
