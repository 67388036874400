<style lang="sass">
%flex-container-base
  display: flex
  flex-direction: column
  align-items: stretch

.flex-container
  @extend %flex-container-base
  justify-content: space-between

.flex-container-messages
  @extend %flex-container-base
  justify-content: flex-end
  // height: calc(100vh - 280px)
// #conversation_list
//   position: relative
//   height: calc(100vh - 235px)
</style>

<template lang="pug">
  v-container(fluid fill-height align-start justify-center)
    v-row(no-gutters justify='center')
      v-col(v-if='(!(selectedChat && selectedChat.id != null) || !isMobile)' md='auto' align-self='start')
        v-toolbar(flat)
          v-toolbar-title Conversas
          v-spacer
          v-btn.ml-4(fab color="primary" small @click="showCreate = true")
            v-icon add
        v-divider
        v-container(v-if="loading" fill-height align-start justify-center)
          v-progress-circular.mt-4(indeterminate size="64")
        perfect-scrollbar(v-else :style='conversationListStyles')
          v-list(v-if='chatsList ? (chatsList.length > 0) : false' two-line :dense='isMobile')
            template(v-for='(chat, i) in chatsList')
              v-list-item(:key="chat.id" @click.stop='showMessages(chat)' :style="{backgroundColor: (chat && selectedChat && chat.id === selectedChat.id) ? '#e6e6e6' : ''}")
                v-list-item-avatar
                  v-img(src='https://cdn1.iconfinder.com/data/icons/user-pictures/100/unknown-512.png')
                v-list-item-content
                  v-list-item-title
                    span.text-truncate {{ chat.name }}
                  //-v-list-item-subtitle
                    span {{ chat.destination_groupcod ? chat.destination_groupcod : 'Diretoria' }}
                v-list-item-action
                  v-list-item-action-text(v-if="chat.updated_time") {{ chat.updated_time | moment('from') }}
                  v-avatar(color="indigo" size="20" v-if="unreadMessages[chat.id] > 0")
                    span.white--text.caption {{unreadMessages[chat.id]}}
              v-divider(v-if='i < chatsList.length - 1' :key="i" inset)
          span.mx-4(v-else) Não há conversas iniciadas
      v-col.d-flex(v-if='!(selectedChat && selectedChat.id != null) && !isMobile' align-self='stretch')
        v-card.pa-5(outlined width="100%")
          v-container(fill-height align-center justify-center)
            span.title Escolha uma conversa para visualizá-la
      v-fade-transition(v-else hide-on-leave leave-absolute)
        v-col.pa-0.d-flex(v-if='selectedChat && selectedChat.id != null' align-self='stretch')
          v-card.flex-container(width="100%")
            div
              v-card(flat)
                v-toolbar.white--text.primary(flat @click="showInfo(selectedChat)")
                  v-btn(v-if='isMobile' icon @click.stop='selectedChat = null')
                    v-icon arrow_back
                  v-toolbar-title.clickable {{ selectedChat.name }}
            div.flex-grow-1.flex-container-messages.align-center(v-show="loadingMessages")
              v-progress-circular(indeterminate size="64")
            div.flex-grow-1.flex-container-messages#customScroll(v-show="!loadingMessages" :style='chatMessagesStyles')
              perfect-scrollbar.px-5
                template(v-for='(m, i) in messageList')
                  Message(:message='m' :user="user.me")
            v-divider
            div
              v-textarea.mx-3(
                v-model="newMessageText"
                :auto-grow="true"
                placeholder="Digite sua mensagem aqui"
                :row-height="24" :rows="1"
                append-outer-icon="send" @click:append-outer="createMessage(newMessageText, 'text-box', { type: 'text' })"
                prepend-icon="attach_file" @click:prepend="attatchFile"
                @keydown.enter.exact.prevent
                @keyup.enter.exact="createMessage(newMessageText, 'text-box', { type: 'text' })"
              )
              //-@keydown.enter.shift.exact="newline"
    v-dialog(v-model='imageDialog' v-if='imageMessage')
      v-container.pa-0
        v-row(no-gutters align='center' justify='center')
          v-col(cols='auto')
            v-card
              v-img(:src='imageMessage.meta.link' contain)
    v-dialog(v-model="showCreate" min-width="500" max-width="700" scrollable)
      v-card
        v-toolbar.elevation-0
          v-spacer
          span.caption Nova conversa
          v-spacer
          v-btn(icon @click="showCreate = false")
            v-icon close
        v-card-text.pa-0.ma-0.no-gutters
          CreateChat(v-on:newchat="newChat")
    v-dialog(v-model="infoDialog" v-if="infoDialog" min-width="500" max-width="700" scrollable)
      ParticipantsList(:chat="selectedChat")
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapState } from 'vuex'
import Message from '../components/chat/Message'
import CreateChat from '../components/chat/CreateChat'
import ParticipantsList from '../components/chat/ParticipantsList'
import firebase from 'firebase'
import Firebase from '../store/firebase'
import randomBytes from 'randombytes'
const firestore = Firebase.firestore

export default {
  fiery: true,
  name: 'chat',
  components: {
    Message,
    CreateChat,
    ParticipantsList
  },
  data () {
    return {
      institution: '',
      loading: true,
      selectedChat: {
        id: null
      },
      loadingMessages: false,
      newMessageText: '',
      showCreate: false,
      infoDialog: false,
      messageList: [],
      messagesRef: {},
      participants: [],
      hasNewConversation: false,
      alwaysScrollToBottom: false,
      unreadMessages: []
    }
  },
  computed: {
    ...mapFields('chat', ['chatsList', 'imageDialog', 'imageMessage']),
    ...mapState(['user']),
    isMobile () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    conversationListStyles () {
      const toolbarHeight = this.isMobile ? 88 : 96
      const styles = { 'height': `calc(100vh - ${this.$vuetify.application.top + this.$vuetify.application.bottom + toolbarHeight}px)` }
      console.log(styles)
      return styles
    },
    chatMessagesStyles () {
      const toolbarHeight = this.isMobile ? 154 : 162
      const styles = { 'height': `calc(100vh - ${this.$vuetify.application.top + this.$vuetify.application.bottom + toolbarHeight}px)` }
      console.log(styles)
      return styles
    }
  },
  async destroyed () {
    this.freeFieryListeners()
  },
  async deactivated () {
    this.freeFieryListeners()
  },
  async created () {
    /**
     *
     * TODO:
     * Utilizar this.$vuetify.application.bottom para saber quanto é o calc(height)
     *
     */
    console.log('VUETIFY', this.$vuetify)
    this.institution = this.$route.params.institution || process.env.VUE_APP_CORDOVA
    /**
     * Importante obter os dados do usuário antes para colocar as mensagens de forma correta.
     */
    await this.$store.dispatch('user/getMe')
    await this.$store.dispatch('chat/list')

    this.unreadMessages = this.$fiery(firestore.collection(`chat/${this.institution}/unreads/`).doc(`${this.user.me.id}`), {
      record: true,
      recordOptions: {
        sync: 'sync',
        update: 'save',
        getChanges: 'getChanges'
      }
    })

    this.unreadMessages.getChanges().then(changes => {
      console.log('CHANGES ON UNREAD MESSAGES', changes)
    })

    this.loading = false
    await this.$store.dispatch('users/listTypes')
  },
  methods: {
    attatchFile () {
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.accept = 'image/*, application/pdf'

      fileInput.onchange = e => {
        const file = e.target.files[0]
        const reader = new FileReader()

        reader.onload = async () => {
          const fileDataUrl = reader.result
          const uploadFileName = randomBytes(30).toString('hex') + '.' + file.name.split('.').slice(1).join('.')

          try {
            // Save file to Firebase
            const storageRef = await firebase.storage().ref()
            const userProfileImageRef = storageRef.child(`users-chat-attachments/${uploadFileName}`)
            const snapshot = await userProfileImageRef.putString(fileDataUrl, 'data_url')
            const url = await snapshot.ref.getDownloadURL()

            // Get file type
            const imageRegExp = /(image)\/*/g
            let type

            if (imageRegExp.test(file.type)) {
              type = 'image'
            } else if (file.type === 'application/pdf') {
              type = 'pdf'
            }

            // Send message
            this.createMessage(file.name, 'file-selector', {
              link: url,
              type
            })
          } catch (err) {
            console.error(err)
          }
        }

        if (file) {
          reader.readAsDataURL(file)
        } else {
          const err = new Error('Invalid file')
          console.error(err)
        }
      }

      fileInput.click()
    },
    freeFieryListeners () {
      console.log('Free fiery listeners')
      this.$fiery.free(this.messagesRef)
      this.$fiery.free(this.messagesList)
    },
    getChanges () {
      this.unreadMessages.getChanges().then(changes => {
        console.log('CHANGES ON UNREAD MESSAGES', changes)
      })
    },
    async createMessage (text, source = 'text-box', metaProps) {
      console.log({ text })
      text = text || this.newMessageText

      const Message = {
        author: {
          id: this.user.me.id,
          name: this.user.me.name,
          image_url: this.user.me.image_url || ''
        },
        meta: {
        },
        created_at: firebase.firestore.FieldValue.serverTimestamp()
      }

      if (source === 'file-selector') {
        Message.meta.link = metaProps.link
        Message.meta.type = metaProps.type
      } else if (source === 'text-box') {
        Message.meta.type = 'text'
        Message.text = text
      }

      firestore.collection(`chat/${this.institution}/conversations/${this.selectedChat.id}/messages`).add(Message)

      // É necessário salvar isso no SQL para que possa ser possível ordenar na query posteriormente.
      this.$store.dispatch('chat/setLastMoment', this.selectedChat)

      // Adding unread status of messages
      for (const participant of this.selectedChat.participants) {
        if (participant.id !== this.user.me.id) {
          firestore.collection(`chat/${this.institution}/unreads/`).doc(participant.id.toString()).set({
            [this.selectedChat.id]: firebase.firestore.FieldValue.increment(1)
          }, { merge: true })
        }
      }

      if (source === 'text-box') {
        this.newMessageText = ''
      }
      this.scrollDown()
    },
    async showInfo (chat) {
      this.infoDialog = true
    },
    async newChat ({ Entity, Room }) {
      console.log('ENTITY', Entity)
      console.log('ROOM', Room)
      this.$store.dispatch(`chat/prependChat`, Room)
      this.showCreate = false
      this.showMessages({
        name: Entity.name,
        id: Room.id
      })
      this.hasNewConversation = true
    },
    scrollDown () {
      const divPerfectScrollbar = document.querySelector('#customScroll > div')
      if (divPerfectScrollbar) {
        this.$nextTick(() => {
          divPerfectScrollbar.scrollTop = divPerfectScrollbar.scrollHeight
        })
      }
    },
    async showMessages (chat) {
      this.selectedChat = chat
      this.$store.dispatch('chat/updateSelectedChat', chat.id)
      this.messageList = []
      this.loadingMessages = true

      const self = this
      const chatsRef = firestore.collection(`chat/${this.institution}/conversations`).doc(chat.id)
      chatsRef.get()
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            // if user already has a chat
            chatsRef.onSnapshot(async (res) => {
              const data = res.data()
              console.log('CHAT DATA', data)
              self.selectedChat.participants = data.participants
              // clear listener for messages ref
              this.freeFieryListeners()
              this.messagesRef = self.$fiery(firestore.collection(`chat/${self.institution}/conversations/${chat.id}/messages`), {
                query: m => m.orderBy('created_at', 'asc'),
                onSuccess: (messagesArray) => {
                  for (const message of messagesArray) {
                    const userId = self.user.me.id
                    if (typeof userId !== typeof undefined) {
                      if (message.author.id === userId) {
                        message.type = 'sent'
                      } else {
                        const messagePath = message['.uid'].replace('1///', '')
                        firestore.doc(messagePath).collection('reads').doc(userId.toString()).get().then(read => {
                          if (!read.exists) {
                            console.log('DECREMETING CHAT UNREADS FOR USER ')
                            firestore.doc(messagePath).collection('reads').doc(userId.toString()).set({
                              read_at: firebase.firestore.FieldValue.serverTimestamp(),
                              user_name: self.user.me.name,
                              user_image_url: self.user.me.image_url || ''
                            })
                            firestore.collection(`chat/${self.institution}/unreads/`).doc(userId.toString()).set({
                              [self.selectedChat.id]: firebase.firestore.FieldValue.increment(-1)
                            }, { merge: true })
                          }
                        })
                      }
                    }
                  }
                  self.messageList = messagesArray

                  /**
                   * Lógica para scrollar para baixo
                   */
                  self.scrollDown()
                }
              })
            })
          }
        })

      this.loadingMessages = false
    }
  }
}
</script>
