<template lang="pug">
  v-container(fluid)
    v-container
      v-row.mb-5
        v-col(style="max-width: 400px; min-width: 280px")
          v-menu(
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          )
            template(v-slot:activator="{ on }")
              v-text-field(
                v-model="dateFormatted"
                label="Data"
                readonly
                v-on="on"
                rounded
                filled
              )
            v-date-picker(
              v-model="date"
              no-title
              @input="menu = false"
            )
        v-col(style="max-width: 400px; min-width: 280px")
          v-select(
            v-model="categoryId"
            :items="categorys.data"
            item-text="name"
            item-value="id"
            :disabled="loadingCategories"
            label="Categoria"
            rounded
            filled
          )
        v-col(style="max-width: 400px; min-width: 280px")
          v-radio-group(v-model="recipient").ma-0.pa-0.pl-5
            v-radio(
              label="Enviadas para turmas"
              value="group"
            )
            v-radio(
              label="Enviadas para alunos"
              value="student"
            )
        v-col(style="max-width: 400px; min-width: 280px")
          v-btn.mt-3(color="primary" @click="getDiarys") Buscar
      v-row
        v-label.ml-2.caption Listando turmas que receberam ou não uma postagem especifica em uma data especifica
    v-row.justify-center(v-if="loadingDiary")
      v-progress-circular(size="70" color="primary" indeterminate)
    v-container(v-else)
      v-list(v-for="filial of filiais" :key="filial.title")
        v-subheader {{ filial.title || 'Sem filial ou filial sem nome' }}
        v-list-item-group
          v-container(fluid)
            v-row(align="start" justify="start" wrap)
              v-col(v-for="turma in filial.turmas" :key="turma.id" cols='auto')
                v-menu.ml-5(v-if="turma.postCount && turma.postCount > 0")
                  template(v-slot:activator="{ on }")
                    v-badge(overlap)
                      template(v-slot:badge) {{turma.postCount}}
                      v-btn(rounded small v-on="on" color="success" @click="loadPosts(turma)") {{ turma.name }}
                  v-list()
                    //- v-list-item(@click="createPost(turma)") Novo
                    v-divider
                    div(v-if="!loadingPosts")
                      v-list-item(v-for="post in menuItems" :key="post.id" @click="editPost(post)")
                        v-list-item-avatar.mr-3
                          v-icon(color="primary" v-if='post.type === "post"') done
                          v-icon(color="warning" v-else) create
                        v-list-item-content
                          v-list-item-title {{ post.title }}
                          v-list-item-subtitle Publicado por: {{ post.creator.name }}
                          v-list-item-subtitle(v-if="post.recipient") Destinatário: {{ post.recipient.name }}
                    v-row.justify-center.align-center.mt-2(v-else)
                      v-progress-circular(indeterminate color="primary")
                //- v-btn(rounded small @click="createPost(turma)" v-else) {{ turma.name }}
                v-btn(rounded small disabled v-else) {{ turma.name }}
    v-dialog(
      v-model="flags.postForm"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    )
      postForm(
        :visible="flags.postForm"
        :post="postForm.initialData"
        :contentOnly="false"
        @close="flags.postForm = false"
        @created="flags.postForm = false"
      )
    v-overlay(:value="overlay")
      v-progress-circular(indeterminate size="70")
</template>
<script>
import Post from '../api/posts.js'
import postEditor from '../components/post/PostEditor'
import _ from 'lodash'
// import Diary from '../api/diary.js'
import postForm from '../components/post/PostForm'
import axios from 'axios'

export default {
  components: {
    postEditor,
    postForm
  },
  data () {
    return {
      flags: {
        postForm: false
      },
      postForm: {
        initialData: null
      },
      diaryList: [],
      filiais: [],
      turmas: [],
      filialsOld: [],
      turmas1Old: [],
      menu: false,
      date: this.$moment().format('YYYY-MM-DD'),
      dateFormatted: this.$moment().format('DD MMM. YYYY'),
      overlay: false,
      menuItems: [],
      categorys: [],
      recipient: 'group',
      categoryId: 4,
      loadingDiary: true,
      loadingCategories: true,
      showDialog: false,
      loadingPosts: false,
      post: {
        title: 'Agenda turma EF12',
        body: 'Example body',
        date: '2019-11-21',
        time: '13:00',
        to: [
          // { type: 'user', userId: '0' },
          { type: 'group', groupCod: 'EFT1', groupTypeAssoc: ['prof', 'aluno', 'parent'] }
        ]
      }
    }
  },
  methods: {
    async getDiarys () {
      this.loadingDiary = true
      var data = {
        institutionSlug: this.$route.params.institution,
        date: this.date,
        categoryId: this.categoryId
      }

      if (this.recipient === 'group') {
        const result = await axios.get(`https://quoti.com.br/node/api/familiaidb/postsdiary/diary/${data.categoryId}/${data.date}`)
        this.diaryList = result.data
        // await this.$store.dispatch('diary/listRecipientsGroups', data)
      } else if (this.recipient === 'student') {
        // await this.$store.dispatch('diary/listRecipientsUsers', data)
      }

      const filiais = _.uniqBy(this.diaryList, 'filial')
      // const ensinos = _.uniqBy(this.diaryList, 'ensinos')

      for (const filial of filiais) {
        console.log('FILIAL:', filial)
        filial.title = filial.filial
        filial.turmas = {}

        // filial.ensinos = ensinos.filter(e => e.filial === filial.title).map((ensino) => {
        //   ensino.turmas = []
        // })

        this.diaryList.filter(t => t.filial === filial.title).map(group => {
          const post = {
            id: group.post_id,
            type: group.post_type,
            title: group.post_title
          }
          if (group.post_creator_id) {
            post.creator = {
              id: group.post_creator_id,
              name: group.post_creator_name
            }
          }
          if (group.post_recipient_id) {
            post.recipient = {
              id: group.post_recipient_id,
              name: group.post_recipient_name
            }
          }
          if (typeof filial.turmas[group.id] === typeof undefined) {
            filial.turmas[group.id] = group
            if (post && post.id > 0) {
              filial.turmas[group.id].posts = [post]
              filial.turmas[group.id].postCount = 1
            }
          } else {
            if (post && post.id > 0) {
              filial.turmas[group.id].posts.push(post)
              filial.turmas[group.id].postCount++
            }
          }
        })
        filial.turmas = _.sortBy(filial.turmas, 'name')
      }

      this.filiais = filiais

      this.loadingDiary = false
    },
    async loadPosts (turma) {
      this.menuItems = turma.posts
    },
    async editPost (post) {
      // this.overlay = true
      // const url = `https://quoti.com.br/dash/#familiaidb?search=${post.title}`
      // window.open(url, 'target_blank')
      // this.overlay = false
    },

    createPost (turma) {
      this.postForm.initialData = {
        title: `Diario da turma ${turma.name}`,
        body: '',
        publication_time: this.$moment(this.post.publication_time).format('YYYY-MM-DD HH:mm:ssZ'),
        category_id: 4,
        medias: [],
        targets: {
          groups: [{
            type: 'group',
            group_cod: turma.cod,
            bonds: ['aluno', 'parent']
          }]
        }
      }

      this.flags.postForm = true
    }
  },
  watch: {
    date (val) {
      this.dateFormatted = this.$moment(this.date).format('DD MMM. YYYY')
    }
  },
  computed: {
    // diaryList () {
    //   return this.$store.getters['diary/list']
    // }
  },
  async created () {
    // this.fetchingGroups = true
    this.getDiarys()
    this.categorys = await Post.getCategories(this.$route.params.institution)
    this.loadingCategories = false
  }
}
</script>
<style>
</style>
