<template lang="pug">
  v-container
    v-btn.primary(fixed fab bottom right @click="create()")
          v-icon add
    v-row(justify-center)
      v-col(cols='12')
        v-data-table(
          :headers="dynamicComponentsTableHeaders"
          :items="dynamicComponents"
          item-key="title"
          class="elevation-1"
          :loading="loadings.data"
        )
          template(v-slot:item.edit="{ item }")
            v-icon(small class="mr-2" @click="editItem(item)") edit
          template(v-slot:item.delete="{ item }")
            v-icon(small @click="deleteItem(item)") delete
    v-dialog(
      v-model='flags.dialog'
      :persistent="loadings.form"
    )
      v-card
        v-toolbar(flat)
          v-card-title(v-if="flags.create") Criar Componente Dinâmico
          v-card-title(v-else) Editar Componente Dinâmico
          v-spacer
          v-btn(icon @click='flags.dialog = false')
            v-icon close
        v-card-text
          v-form
            v-row(justify='center')
              v-col(md=6)
                span.subtitle-1.font-weight-medium Título
                v-text-field(v-model="form.title" outlined single-line clearable)
              v-col(md=6)
                span.subtitle-1.font-weight-medium Tipo
                v-select(
                  :items="types"
                  outlined
                  v-model="form.type"
                )
              v-col(md=4)
                span.subtitle-1.font-weight-medium Caminho
                v-text-field(v-model='form.path' outlined single-line :disabled="!flags.create")
              v-col(md=4)
                span.subtitle-1.font-weight-medium Ícones
                v-autocomplete(outlined v-model="form.icon" :items="iconList")
                  template(v-slot:item="data")
                      v-divider
                      v-list-item
                        v-list-item-avatar(size="40")
                          v-icon {{data.item}}
                        v-list-item-content {{data.item}}
                  template(v-slot:selection="data")
                      v-divider
                      v-list-item
                        v-list-item-avatar(size="20")
                          v-icon {{data.item}}
                        v-list-item-content {{data.item}}
              v-col(md=4)
                span.subtitle-1.font-weight-medium Cor do Ícone
                v-autocomplete(outlined v-model="form.color" :items="colorsList" item-text="color" item-value="color")
                  template(v-slot:item="data")
                    v-divider
                    v-list-item
                      v-list-item-avatar(size="40" :color="data.item")
                      v-list-item-content {{data.item}}
                  template(v-slot:selection="data")
                    v-divider
                    v-list-item
                      v-list-item-avatar(size="20" :color="data.item")
                      v-list-item-content {{data.item}}
              v-col(md="6")
                span.subtitle-1.font-weight-medium Grupos
                v-data-table(
                  v-model="form.groups"
                  :headers="groupsTableHeaders"
                  :items="dynamicComponentsGroupsAssociations"
                  item-key="cod"
                  :loading="loadings.groups"
                  :items-per-page="5"
                )
                  template(v-slot:item.to_group="{ item }")
                    tr
                      td(align="center")
                        v-checkbox(v-model="item.to_group")
                  template(v-slot:item.to_user="{ item }")
                    tr
                      td(align="center")
                        v-checkbox(v-model="item.to_user")
              v-col(md="6")
                v-avatar
                  v-tooltip(bottom)
                    template(v-slot:activator="{ on }")
                      v-icon(v-on="on") help
                    span O Componente dinâmico pode ser um arquivo .vue que você faz upload na plataforma ou uma URL da web.
                span.subtitle-1.font-weight-medium Origem do componente dinamico
                v-text-field(disabled v-model="form.url" placeholder="URL da web" outlined single-line clearable)
                dynamic-components-files-dropzone(
                  ref="dynamicComponentsFilesDropzone"
                  @pending="loadings.form = true"
                  @uploaded="addFile"
                  @removed="removeFile()"
                )
                v-btn(text :href="this.vueFileDownload" v-if="!flags.create").mt-5
                  v-icon mdi-file-download-outline
                  span {{ form.fileName }}
        v-card-actions.pb-3
          v-spacer
          v-scale-transition
            v-btn(@click="save()" outlined color='primary' :loading="loadings.form" v-if="flags.create") Criar Componente Dinâmico
            v-btn(@click="save()" outlined color='primary' :loading="loadings.form" v-else) Salvar Componente Dinâmico
</template>

<script>
import dynamicComponentsFilesDropzone from '../components/dynamicComponents/DynamicComponentsFilesDropzone'
import dynamicComponentsApi from '../api/dynamicComponents'
import icons from '../assets/icons'
import colors from '../assets/colors'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  components: {
    dynamicComponentsFilesDropzone
  },
  data: function () {
    return {
      iconList: icons,
      colorsList: colors,
      dynamicComponents: [],
      dynamicComponentsGroupsAssociations: [],
      dynamicComponentsTableHeaders: [
        {
          text: 'Título',
          align: 'left',
          value: 'title'
        },
        {
          text: 'Tipo',
          align: 'left',
          value: 'type'
        },
        {
          text: 'Editar',
          width: '100',
          align: 'center',
          sortable: false,
          value: 'edit'
        },
        {
          text: 'Deletar',
          width: '100',
          align: 'center',
          sortable: false,
          value: 'delete'
        }
      ],
      groupsTableHeaders: [
        {
          text: 'Código',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Para o Grupo',
          align: 'center',
          value: 'to_group'
        },
        {
          text: 'Para Usuários',
          align: 'center',
          value: 'to_user'
        }
      ],
      types: ['', 'Agenda Diária', 'Menu Lateral', 'Menu Categoria'],
      flags: {
        dialog: false,
        create: false
      },
      loadings: {
        data: false,
        form: false,
        groups: false
      },
      form: {
        title: null,
        type: null,
        path: null,
        fileName: null,
        color: '',
        icon: '',
        url: '',
        groups: []
      },
      vueFileDownload: null,
      originalFileName: null
    }
  },
  watch: {
    'flags.dialog': function (value) {
      if (!value) {
        this.clear()
      }
    }
  },
  computed: {
    ...mapState(['groups'])
  },
  methods: {
    clear () {
      // Clear form
      this.form.title = null
      this.form.path = null
      this.form.type = null
      this.form.fileName = null
      this.form.color = ''
      this.form.icon = ''
      this.form.url = ''
      this.form.groups = []

      // Clear assocs flags
      this.dynamicComponentsGroupsAssociations.forEach(assoc => {
        assoc.to_group = false
        assoc.to_user = false
      })

      // Clear dropzone
      this.$refs.dynamicComponentsFilesDropzone.clearServerFiles()
    },
    async deleteItem (dynamicComponent) {
    },
    async editItem (dynamicComponent) {
      // Select dynamic component
      this.form.title = dynamicComponent.title
      this.form.type = dynamicComponent.type
      this.form.path = dynamicComponent.path
      this.form.fileName = dynamicComponent.file_vue_prefix
      this.originalFileName = dynamicComponent.file_vue_prefix
      this.form.icon = dynamicComponent.icon

      // Set dynamic component file dowload link
      const institution = this.$route.params.institution || process.env.VUE_APP_CORDOVA
      this.vueFileDownload = `https://storage.cloud.google.com/dynamic-components/${institution}/${this.form.fileName}`

      // Set edit flags
      this.flags.create = false
      this.flags.dialog = true

      // Get dynamic component groups associations
      this.loadings.groups = true
      const groupsAssociations = await this.$store.dispatch('dynamicComponents/listGroups', this.form.path)
      groupsAssociations.forEach(assoc => {
        const tableItem = this.dynamicComponentsGroupsAssociations.find(tableItemAssoc => tableItemAssoc.cod === assoc.cod)
        if (typeof tableItem !== typeof undefined) {
          tableItem.to_group = assoc.to_group
          tableItem.to_user = assoc.to_user
          this.form.groups.push(tableItem)
        }
      })
      this.loadings.groups = false
    },
    create () {
      // Set create flags
      this.flags.create = true
      this.flags.dialog = true
    },
    async addFile (data) {
      // Delete previous file
      try {
        if (this.form.fileName) {
          await axios.delete(`https://content.googleapis.com/storage/v1/b/dynamic-components/o/${this.form.fileName.replace('/', '%2F')}`)
        }
      } catch (err) {
        console.error(err)
      }

      // Set new filename
      this.form.fileName = data.name

      this.loadings.form = false
    },
    removeFile () {
      // Set original file name
      if (this.flags.create) {
        this.form.fileName = null
      } else {
        this.form.fileName = this.originalFileName
      }

      this.loadings.form = false
    },
    async save () {
      this.loadings.form = true

      try {
        /**
         *
         * Arrumei isso daqui da forma mais rápida possível.. na prática dynamicComponentsGroupsAssociations deveria ser form.groups
         *
         */

        this.form.groups = this.dynamicComponentsGroupsAssociations.filter(group => group.to_group || group.to_user)

        // Save dynamic component
        const savedMetadata = await dynamicComponentsApi.saveMetadata({
          title: this.form.title,
          type: this.form.type || undefined,
          file_vue_prefix: this.form.fileName,
          color: this.form.color,
          icon: this.form.icon,
          url: this.form.url,
          groups: this.form.groups,
          path: this.form.path
        })

        // Update data on table
        if (this.flags.create) {
          this.dynamicComponents.push(savedMetadata)
        } else {
          const dynamicComponent = this.dynamicComponents.find(d => d.path === this.form.path)
          Object.assign(dynamicComponent, savedMetadata)
        }
      } catch (err) {
        console.error(err)
      }

      this.loadings.form = false
      this.flags.dialog = false
    }
  },
  async created () {
    // Get dynamic components
    this.loadings.data = true
    await this.$store.dispatch('groups/list')
    this.dynamicComponents = await this.$store.dispatch('dynamicComponents/list')
    this.loadings.data = false

    // Set default dynamic components assocs data
    this.dynamicComponentsGroupsAssociations = this.groups.groups.map(g => {
      return {
        ...g,
        to_group: false,
        to_user: false
      }
    })
  }
}
</script>
