<template lang="pug">
  v-container(fluid)
    div(v-if="!postCategories")
      v-row.justify-center
        v-progress-circular(size="70" indeterminate color="primary")
    div(v-else)
      v-container(v-if="!showingPosts")
        v-row(justify-center)
          v-col(cols='12')
            span.caption.grey--text Selecione uma categoria para continuar
          v-col(cols='12')
            v-list
              div(v-for="(category,index) in postCategories" :key="category.id")
                v-divider(v-if='index > 0')
                v-list-item(@click="selectCategory(category)" large)
                  v-list-item-avatar
                    v-icon(:color="category.color") {{category.icon}}
                  v-list-item-content {{category.name}}
      v-container(v-else-if="showingPosts && !categoryPosts")
        v-row.justify-center
          v-progress-circular(size="70" indeterminate color="primary")
      v-container#post-list(fluid v-else)
        v-btn(text outiline @click="backToCategories")
          v-icon mdi-keyboard-backspace
          span Voltar
        v-row
          v-col(xs='12' sm='8' lg='6' offset-sm='2' offset-lg='3')
            Post(v-for="(item, index) in categoryPosts" :key="item.id" :post='item')
          v-col.text-center( cols='12' xs='12' sm='8' lg='6' offset-sm='2' offset-lg='3' v-show="loading" size=100 key='flex')
            v-progress-circular.mt-5(:size="50" color="primary" indeterminate)
</template>
<script>
import PostApi from '../api/posts.js'
import Post from '../components/feed/PostViewer'
export default {
  components: {
    Post
  },
  data () {
    return {
      step: 0,
      postCategories: null,
      showingPosts: false,
      categoryPosts: null,
      selectedCategory: null,
      loading: false
    }
  },
  async created () {
    // this.$store.dispatch('users/list')
    var resCategories = await PostApi.getCategories(this.$route.params.institution)
    this.postCategories = resCategories.data
  },
  methods: {
    backToCategories () {
      this.showingPosts = false
      this.categoryPosts = null
      this.loading = false
      this.selectedCategory = null
    },
    async selectCategory (category) {
      this.selectedCategory = category
      this.showingPosts = true
      this.categoryPosts = await PostApi.list({
        category_id: this.selectedCategory.id,
        removed: false
      })
    },
    async handleScroll (e) {
      const listElm = window
      if (listElm.scrollY + (4 * listElm.innerHeight) >= document.body.scrollHeight && this.loading === false && this.showingPosts) {
        this.loadMore()
      }
    },
    async loadMore () {
      this.loading = true
      var lastPost = this.categoryPosts[this.categoryPosts.length - 1]
      let lastItem = lastPost ? lastPost.created_time : ''
      const queryParams = {
        last: lastItem,
        category_id: this.selectedCategory.id,
        alreadyPublished: true,
        removed: false
      }
      const list = await PostApi.list(queryParams)
      this.categoryPosts = this.categoryPosts.concat(list)
      this.loading = false
    }
  },
  destroyed () {
    // Detect when scrolled to bottom.
    const listElm = window
    listElm.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    // Detect when scrolled to bottom.
    const listElm = window
    listElm.addEventListener('scroll', this.handleScroll)
  }
}
</script>
<style scoped>

</style>
