<template lang="pug">
  v-container
    v-row(align='center' justify='center' v-if='loading')
      v-col.text-center(cols='12')
        v-progress-circular.mt-5(:size="50" color="primary" indeterminate)
    v-row(v-else)
      component(
        :is="component"
      )
</template>
<script>
import importExternalComponent from '../utils/importExternalComponent'

export default {
  data () {
    return {
      dynamicComponentPath: '',
      component: null,
      loading: false
    }
  },
  async mounted () {
    this.dynamicComponentPath = this.$route.params.dynamicComponentPath

    this.loading = true
    this.component = await importExternalComponent(this.dynamicComponentPath)
    this.loading = false
  }
}
</script>
