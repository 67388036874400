<template lang="pug">
  v-container(fill-height)
    v-row
      v-col.text-center(v-if="loading")
        v-progress-circular(:size="50" color="primary" indeterminate)
      v-col(v-else)
        v-list(two-line)
          div(v-for='(contact, i) in contacts')
            v-list-item(:style="{'align-items':'center'}" @click="goAction(contact)")
              v-list-item-avatar(tile size=50)
                div(v-html='contact.icon' v-if="!contact.icon")
                v-icon(:color="contact.color" v-else) {{contact.icon}}
              v-list-item-content
                v-list-item-title {{contact.title}}
                v-list-item-subtitle {{contact.display}}
            v-divider
          div(v-for='(social, i) in socials')
            v-list-item(@click="goAction(social)")
              v-list-item-avatar(tile size=50)
                div(v-html='social.icon' v-if="!social.icon")
                v-icon(:color="social.color" v-else) {{social.icon}}
              v-list-item-content
                v-list-item-title {{social.title}}
                v-list-item-subtitle {{social.display}}
            v-divider
</template>
<script>
import contacts from '../api/organization.js'
export default {
  data () {
    return {
      contacts: null,
      socials: null,
      loading: true
    }
  },
  methods: {
    goHome () {
      this.$router.push({
        name: 'home'
      })
    },
    goAction (item) {
      /**
       * Deveria ser value... mas este código é "herança" e estava assim
       */
      window.open(item.subtitle, '_system')
    }
  },
  async created () {
    var res = await contacts.list()
    this.loading = false
    this.contacts = res.data.contacts
    this.socials = res.data.socialnetworks
  }
}
</script>
<style scoped lang="stylus">

</style>
