<template lang="pug">
  helloworld
</template>

<script>
import importExternalComponent from '../utils/importExternalComponent'

export default {
  components: {
    'helloworld': () => importExternalComponent('/test/helloworld')
  }
}
</script>
